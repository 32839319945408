<template>
  <tr :id="guid" :class="setclass()">
    <td>
      <button
        class="btn btn-sm bg-warning"
        @click="setSelectedId($event, guid)"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
      >
        <font-awesome-icon icon="fa-pen-to-square" />
      </button>
    </td>

    <td>
      <button
        class="btn btn-sm bg-primary"
        @click="usePeriodicTransComp($event, guid)"
      >
        <font-awesome-icon icon="fa-floppy-disk" />
      </button>
    </td>

    <td>
      <button
        class="btn btn-sm bg-primary ms-3 me-1"
        @click="increaseDecrease($event, guid, 'increase', amount)"
      >
        <font-awesome-icon icon="fa-square-plus" /></button
      >&nbsp;
      <button
        class="btn btn-sm bg-primary"
        @click="increaseDecrease($event, guid, 'decrease', amount)"
      >
        <font-awesome-icon icon="fa-square-minus" />
      </button>
    </td>

    <td :class="setclass()" v-if="guidaccount">
      {{ accDesc }}
    </td>

    <td v-if="amount" :class="setclass()" style="text-align: right">
      {{ formatCurrency(amount) }}
    </td>

    <td v-if="description" :class="setclass()" style="vertical-align: middle">
      {{ description }}
    </td>
    <td v-else>
      <span v-if="description" :class="setclass()" style="color: blue"></span>
      &nbsp;
    </td>

    <td :class="setclass()" style="vertical-align: middle">
      {{ kontoDesc }}
    </td>

    <td :class="setclass()" style="vertical-align: middle">
      {{ remark1 }}
    </td>

    <td :class="setclass()" style="vertical-align: middle">
      {{ orderPeriodic }}
    </td>

    <td>
      <button
        class="btn btn-danger btn-sm"
        @click="deleteSelectedId($event, guid)"
      >
        <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script>
import appUtils from '../utils/appUtils';

export default {
  emits: ['select-id', 'delete-id', 'use-periodic-trans', 'increase-decrease'],
  props: [
    'guid',
    'guidaccount',
    'description',
    'amount',
    'guidknt1',
    'guidknt2',
    'guidknt3',
    'guidknt4',
    'remark1',
    'orderPeriodic',
    'selectedtransid',
    'accDesc',
    'kontoDesc',
  ],

  data() {
    return { isActive: false };
  },

  methods: {
    formatCurrency(parNumber) {
      return appUtils.formatCurrency(parNumber);
    },

    setSelectedId(event, value) {
      this.$emit('select-id', value);
    },

    deleteSelectedId(event, value) {
      this.$emit('delete-id', value);
    },

    usePeriodicTransComp(event, value) {
      this.$emit('use-periodic-trans', value);
    },

    increaseDecrease(event, value, increaseDecrease, amount) {
      console.log(value);
      console.log(increaseDecrease);

      this.$emit('increase-decrease', { value, increaseDecrease, amount });
    },

    setclass() {
      {
        return {
          isActivejr: this.selectedtransid === this.guid ? true : false,
        };
      }
    },
  },

  computed: {
    getComputed123() {
      return '';
    },
  },
};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.75rem;
}

h3 {
  font-size: 1.3rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.3rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
