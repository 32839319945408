<template>
  <div class="text-light">
    <base-dialog
      :show="!!baseDialogMessage"
      title="Transaction saved!"
      @close="handleError"
    >
      <p class="text-warning">{{ baseDialogMessage }}</p>
    </base-dialog>

    <!-- <pre class="text-light">{{ amount }}</pre>
    <pre class="text-light">{{ amountOverride }}</pre>

    <pre class="text-light">{{ getDisplayPeriodic }}</pre>
    <pre class="text-light">{{ selectedDate }}</pre>
    <pre class="text-light">{{ transDataNew.value }}</pre> -->

    <!-- fast search description -->
    <div class="row mb-3 text-warning">
      <div class="col-sm-4 ms-1">
        <label for="searchText" class="col-sm-3 col-form-label">Search </label>

        <br />
        <input
          class="form-control-sm"
          type="text"
          id="searchText"
          v-model="searchText"
        />
      </div>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <!-- DIALOG EDIT -->
    <div
      class="modal fade"
      id="modaltransaction"
      tabindex="-1"
      aria-labelledby="vLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-warning">
            <h5 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
              {{ modalTransactionCaption }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body bg-dark text-light">
            <form @submit.prevent="">
              <!-- Amount -->
              <div class="row">
                <label for="amount" class="col-sm-3 col-form-label text-end"
                  >Amount
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm"
                    type="text"
                    id="amount"
                    v-model="amount"
                  />
                </div>
              </div>

              <!-- Description -->
              <div class="row">
                <label
                  for="description"
                  class="col-sm-3 col-form-label text-end"
                  >Description
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm"
                    type="text"
                    id="description"
                    v-model.trim="description"
                  />
                </div>
              </div>

              <!-- Account -->
              <div class="row">
                <label for="account" class="col-sm-3 col-form-label text-end"
                  >Account
                </label>

                <div class="col-sm-9">
                  <account-dropdown
                    :modelValue="selectedAcc"
                    @onchange="onChangeAccount"
                  ></account-dropdown>
                </div>
              </div>

              <!-- Category 1 -->
              <div class="row">
                <label class="col-sm-3 col-form-label text-end" for="konto1"
                  >Category 1
                </label>

                <div class="col-sm-9">
                  <category-dropdown
                    :level="1"
                    :parentCategory="0"
                    :modelValue="selectedKonto1"
                    @on-change="onChangeComp($event, 1)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 1)"
                    id="konto1"
                    name="selectedKonto1"
                    v-model="selectedKonto1"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getKonto1"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="row">
                <label class="col-sm-3 col-form-label text-end" for="konto2"
                  >Category 2
                </label>
                <div class="col-sm-9">
                  <category-dropdown
                    :level="2"
                    :parentCategory="selectedKonto1"
                    :modelValue="selectedKonto2"
                    @on-change="onChangeComp($event, 2)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 2)"
                    id="konto2"
                    name="selectedKonto2"
                    v-model="selectedKonto2"
                  >
                    <option :key="0" :value="0"></option>

                    <option
                      v-for="item in getKonto2"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="row">
                <label class="col-sm-3 col-form-label text-end" for="konto3"
                  >Category 3
                </label>
                <div class="col-sm-9">
                  <category-dropdown
                    :level="3"
                    :parentCategory="selectedKonto2"
                    :modelValue="selectedKonto3"
                    @on-change="onChangeComp($event, 3)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 3)"
                    id="konto3"
                    name="selectedKonto3"
                    v-model="selectedKonto3"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getKonto3"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="row">
                <label
                  class="col-sm-3 col-form-label text-end align-bottom"
                  for="konto4"
                  >Category 4
                </label>
                <div class="col-sm-9">
                  <category-dropdown
                    :level="4"
                    :parentCategory="selectedKonto3"
                    :modelValue="selectedKonto4"
                    @on-change="onChangeComp($event, 4)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 4)"
                    id="konto4"
                    name="selectedKonto4"
                    v-model="selectedKonto4"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getKonto4"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="row mb-2">
                <label for="remark1" class="col-sm-3 col-form-label text-end"
                  >Remark
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control-sm"
                    type="text"
                    id="remark1"
                    v-model.trim="remark1"
                  />
                </div>
              </div>

              <div class="row mb-2">
                <label
                  for="orderPeriodic"
                  class="col-sm-3 col-form-label text-end"
                  >Order
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control-sm"
                    type="number"
                    id="orderPeriodic"
                    v-model="orderPeriodic"
                  />
                </div>
              </div>

              <!-- <div class="float-end"> -->

              <div class="float-end">
                <!-- <div class="col-sm-6"></div> -->

                <!-- <div class="col-sm-3"> -->
                <base-button class="text-end" data-bs-dismiss="modal"
                  >Cancel</base-button
                >

                <base-button
                  class="text-end"
                  data-bs-dismiss="modal"
                  data-bs-target="#modaltransaction"
                  :disabled="disableSave()"
                  @click="savePeriodic()"
                  >Save <font-awesome-icon icon="fa-floppy-disk"
                /></base-button>

                <!-- data-bs-target="#modaltransaction" -->
                <!-- </div>

                <div class="col-sm-3"> -->

                <!-- </div> -->
              </div>

              <table v-if="1 == 0">
                <tr>
                  <td>
                    <p>{{ selectedKonto1 }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{ selectedKonto2 }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{ selectedKonto3 }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{ selectedKonto4 }}</p>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END DIALOG -->

    <base-card class="bg-dark text-light"
      ><table class="table table-striped table-dark">
        <thead>
          <tr class="text-warning">
            <th>Edit</th>
            <th>Use</th>
            <th>
              <base-link :fieldTitle="'Increase/Decrease'"></base-link>
            </th>
            <th>
              <base-link
                :fieldName="'accDesc'"
                :fieldTitle="'Account'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th class="text-end">
              <base-link
                :fieldName="'amount'"
                :fieldTitle="'Amount'"
                @sort-data="sortData"
              ></base-link>
            </th>
            <th>
              <base-link
                :fieldName="'description'"
                :fieldTitle="'Description'"
                @sort-data="sortData"
              ></base-link>
            </th>
            <th>
              <base-link
                :fieldName="'kontoDesc'"
                :fieldTitle="'Category'"
                @sort-data="sortData"
              ></base-link>
            </th>
            <th>
              <base-link
                :fieldName="'remark1'"
                :fieldTitle="'Remark'"
                @sort-data="sortData"
              ></base-link>
            </th>
            <th>
              <base-link
                :fieldName="'orderPeriodic'"
                :fieldTitle="'Order'"
                @sort-data="sortData"
              ></base-link>
            </th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody class="align-middle">
          <!-- :idaccount="item.idaccount" -->
          <periodic-item
            @select-id="selectId"
            @delete-id="deleteId"
            @use-periodic-trans="usePeriodicTrans"
            @increase-decrease="handleIncreaseDecrease"
            v-for="item in getDisplayPeriodic"
            :key="item.guid"
            :index="item.guid"
            :guid="item.guid"
            :guidaccount="item.guidaccount"
            :description="item.description"
            :amount="item.amount"
            :guidknt1="item.guidknt1"
            :guidknt2="item.guidknt2"
            :guidknt3="item.guidknt3"
            :guidknt4="item.guidknt4"
            :remark1="item.remark1"
            :orderPeriodic="item.orderPeriodic"
            :selectedtransid="getSelectedGuid"
            :accDesc="item.accDesc"
            :kontoDesc="item.kontoDesc"
          ></periodic-item>
        </tbody>
      </table>
    </base-card>

    <base-card>
      <h5 class="mb-3 ms-1">Use periodic transaction</h5>

      <div class="row mt-2">
        <label for="selectedDate1" class="col-sm-2 col-form-label text-end"
          >Date
        </label>
        <div class="col-sm-6 mt-1">
          <div class="row">
            <div class="btn-group mr-2" role="group" aria-label="First group">
              <base-date-picker
                @ondatechange="ondatechangehere"
                id="selectedDate1"
                :year-range="yearRange"
                :min-date="minDate"
                :max-date="maxDate"
                :modelValue="selectedDate"
              >
              </base-date-picker>

              <button
                class="base-button align-items-sm-start ms-2"
                @click="shiftDate(-1)"
              >
                <font-awesome-icon icon="fa-square-minus" />
              </button>

              <button
                class="base-button align-items-sm-start"
                @click="shiftDate(1)"
              >
                <font-awesome-icon icon="fa-square-plus" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- description override -->
      <div class="row mt-4">
        <label for="desc_override" class="col-sm-2 col-form-label text-end"
          >Description override
        </label>

        <div class="col-sm-5">
          <input
            class="form-control-sm"
            type="text"
            id="desc_override"
            v-model="descOverride"
          />
        </div>
      </div>

      <!-- amount_override -->
      <div class="row mt-4">
        <label for="amount_override" class="col-sm-2 col-form-label text-end"
          >Amount override
        </label>

        <div class="col-sm-5">
          <input
            class="form-control-sm"
            type="text"
            id="amount_override"
            v-model="amountOverride"
          />

          <button class="base-button ms-1" @click="resetOveride">Reset</button>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script setup>
import { ref, toRef, reactive, computed, onMounted } from 'vue';
import appUtils from '../../components/utils/appUtils.js';
import accountDropdown from '../../components/accounts/accountDropdown.vue';
import { savePeriodicTransaction } from './utilsTransactions.js';
import { deletePeriodic } from './utilsTransactionDelete.js';
import PeriodicItem from '../../components/periodic/PeriodicItem.vue';
//import { useStore } from 'vuex';
import { getDatabase } from 'firebase/database';
import {
  saveTransactionAtomic,
  getGuidLastLevelKonto,
  onChangeCategory,
} from './utilsTransactions.js';
//import Datepicker from '@vuepic/vue-datepicker';
import { usePeriodicStore } from '../../stores/PeriodicStore';
import CategoryDropdown from '../../components/categories/CategoryDropdown.vue';

import BaseLink from '../../components/ui/BaseLink.vue';

const modalTransactionCaption = ref('Add periodic transaction');
const minDate = String(Number(appUtils.getCurrentYear()) - 1) + '-01-01';
const maxDate = String(Number(appUtils.getCurrentYear()) + 1) + '-12-31';
const yearRange = [
  String(Number(appUtils.getCurrentYear()) - 2),
  String(Number(appUtils.getCurrentYear()) + 2),
];

let selectedAcc = ref('0');
//const toast = useToast();

const amount = ref(0);
const amountOverride = ref(0);
const description = ref('');
const descOverride = ref('');

const selectedKonto1 = ref(0);
const selectedKonto2 = ref(0);
const selectedKonto3 = ref(0);
const selectedKonto4 = ref(0);
const remark1 = ref('');
const orderPeriodic = ref(0);
const selectedTransGuid = ref(null);
const transDataOld = reactive({});
const selectedTrans = toRef({});
//const store = useStore();
const baseDialogMessage = ref(null);
const lastSort = ref('');

const searchText = ref(null);

const selectedDate = ref(
  appUtils.getCurrentYearMonth() +
    '-' +
    String(new Date().getDate()).padStart(2, '0')
);

const pageStore = usePeriodicStore();
const dataSorting = ref('');
const dataSortingOrder = ref('asc');

// const showToast = (guid) => {
//   toast('Transaction Saved<br>ID: ' + guid, {
//     theme: 'dark',
//     type: 'info',
//     autoClose: 2000,
//     transition: 'slide',
//     dangerouslyHTMLString: true,
//   });
// };

onMounted(() => {
  appUtils.conlog('PeriodicTrans => onMounted');
  //searchText.value.focus();
  selectId();
  sortData('orderPeriodic');
});

async function savePeriodic() {
  //saving to PERIODIC collection
  const transNew = transDataNew.value;
  let transOld = null;

  if (selectedTransGuid.value) transOld = transDataOld.value;

  if (!transNew.description) transNew.description = null;
  if (!transNew.remark1) transNew.remark1 = null;
  if (!transNew.remark2) transNew.remark2 = null;
  if (!transNew.remark3) transNew.remark3 = null;
  if (!transNew.remark4) transNew.remark4 = null;
  if (!transNew.orderPeriodic) transNew.orderPeriodic = null;

  // console.log('========  transNew  ================');
  // console.log(transNew);
  // console.log('====================================');

  savePeriodicTransaction({
    new: transNew,
    old: transOld,
  })
    .then((values) => {
      console.log(values);
      searchTrans(values.guid);
    })
    .catch((error) => console.log(error));
}

function toggleSortingOrder() {
  if (dataSortingOrder.value == 'asc') {
    dataSortingOrder.value = 'desc';
  } else {
    dataSortingOrder.value = 'asc';
  }
}

function searchTrans(guid = null) {
  var currentSort = lastSort.value.repeat(1);
  console.log('---------------- currentSort ----------------');
  console.log(currentSort);

  const db = getDatabase();

  // const periodicStore = usePeriodicStore();
  pageStore
    .loadPeriodic({
      db,
    })
    // store
    //   .dispatch('periodic/loadPeriodic', {
    //     db,
    //   })
    .then(() => {
      if (guid) selectId(guid);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      console.log('finally searchTrans()');

      toggleSortingOrder();
      sortData(currentSort);

      // toast.add({
      //   severity: 'info',
      //   summary: 'Message',
      //   detail: 'Transaction Saved!',
      //   life: 2000,
      //   styleClass: 'd',
      //   contentStyleClass: '',
      // });
    });
}

const transDataNew = computed(function () {
  const transData = {
    amount: parseFloat(amount.value),
    guidaccount: selectedAcc.value ? selectedAcc.value : null,
    guidknt1: selectedKonto1.value ? selectedKonto1.value : null,
    guidknt2: selectedKonto2.value ? selectedKonto2.value : null,
    guidknt3: selectedKonto3.value ? selectedKonto3.value : null,
    guidknt4: selectedKonto4.value ? selectedKonto4.value : null,
    description: description.value,
    remark1: remark1.value,
    orderPeriodic: parseInt(orderPeriodic.value),
  };
  return transData;
});

function addNewRec() {
  modalTransactionCaption.value = 'Add Periodic Transaction';
  selectedTransGuid.value = null;

  amount.value = 0;
  description.value = '';
  remark1.value = '';
  orderPeriodic.value = 0;
}

function deleteId(transGuid) {
  deletePeriodic(transGuid);
}

function selectId(selectedGuid) {
  const trans = getDisplayPeriodic;

  if (!selectedGuid) return;

  for (var i = 0; i < trans.value.length; i++) {
    if (trans.value[i].guid === selectedGuid) {
      console.log('selectId => found:', selectedGuid);

      transDataOld.value = trans.value[i];

      if (typeof transDataOld.value.guidknt1 === 'undefined')
        transDataOld.value.guidknt1 = null;
      if (typeof transDataOld.value.guidknt2 === 'undefined')
        transDataOld.value.guidknt2 = null;
      if (typeof transDataOld.value.guidknt3 === 'undefined')
        transDataOld.value.guidknt3 = null;
      if (typeof transDataOld.value.guidknt4 === 'undefined')
        transDataOld.value.guidknt4 = null;

      selectedTrans.value = trans.value[i];

      amount.value = selectedTrans.value.amount;
      description.value = selectedTrans.value.description;
      selectedTransGuid.value = selectedTrans.value.guid;

      modalTransactionCaption.value =
        'Edit Periodic Transaction [' + selectedTransGuid.value + ']';

      selectedAcc.value = selectedTrans.value.guidaccount;
      selectedKonto1.value = selectedTrans.value.guidknt1;
      selectedKonto2.value = selectedTrans.value.guidknt2;
      selectedKonto3.value = selectedTrans.value.guidknt3;
      selectedKonto4.value = selectedTrans.value.guidknt4;
      remark1.value = selectedTrans.value.remark1;
      orderPeriodic.value = selectedTrans.value.orderPeriodic;
      break;
    }
  }
}

async function usePeriodicTrans(selectedGuid) {
  //saving to TRANSACTIONS collection

  selectId(selectedGuid);
  let transNew = {};
  Object.assign(transNew, transDataNew.value);

  // console.log('========  START usePeriodicTrans  ================');
  // console.log(transNew);
  // console.log('====================================');

  transNew.date = appUtils.getDateAsString(selectedDate.value);
  transNew.termin = null;
  transNew.year = null;

  //if value is empty - like empty string - we set it
  //to null to so it doesn't appear in database
  if (!transNew.description) transNew.description = null;
  if (!transNew.remark1) transNew.remark1 = null;
  if (!transNew.remark2) transNew.remark2 = null;
  if (!transNew.remark3) transNew.remark3 = null;
  if (!transNew.remark4) transNew.remark4 = null;

  let guidLastLevelKonto = getGuidLastLevelKonto(
    selectedKonto1.value,
    selectedKonto2.value,
    selectedKonto3.value,
    selectedKonto4.value
  );

  if (amountOverride.value) {
    if (amountOverride.value != 0) {
      transNew.amount = amountOverride.value;
    }
  }

  if (descOverride.value) {
    if (descOverride.value != '') {
      transNew.description = descOverride.value;
    }
  }

  if (!transNew.guidkonto) transNew.guidkonto = guidLastLevelKonto;
  // console.log('========  usePeriodicTrans  ================');
  // console.log(transNew);
  // console.log('====================================');

  saveTransactionAtomic({
    new: transNew,
    old: null,
  })
    .then((values) => {
      //console.log(values);
      //baseDialogMessage.value = 'ID of transaction: ' + values.guid;
      amountOverride.value = 0;
      descOverride.value = '';
      appUtils.showToast('Transaction Saved<br>ID: ' + values.guid);
      //searchTrans(values.guid);
    })
    .catch((error) => {
      console.log(error);
    });
}

function disableSave() {
  return false;
}

const getSelectedGuid = computed(function () {
  if (selectedTransGuid.value) {
    return selectedTransGuid.value;
  } else {
    return '';
  }
});

function onChangeAccount(value) {
  selectedAcc.value = value;
}

function sortData(field) {
  lastSort.value = field;
  appUtils.setSortingParams(field, dataSorting, dataSortingOrder, pageStore);
}

const getDisplayPeriodic = computed(function () {
  let tmp = pageStore.periodicGuid;
  // console.log('getDisplayPeriodic');
  if (searchText.value) {
    //tmp = appUtils.fastFilter(tmp, searchText.value);
    tmp = fastFilterPeriodic(tmp, searchText.value);
  }
  return tmp;
});

function fastFilterPeriodic(tmp, searchText) {
  const fieldArr = ['description', 'accDesc', 'kontoDesc', 'remark1'];

  if (searchText.length <= 1) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}

function handleError() {
  baseDialogMessage.value = null;
  console.log('handleError()');
}

function onChangeComp(eventArgs, level) {
  //appUtils.conlog(eventArgs); // .value);

  onChangeCategory(
    eventArgs,
    level,
    selectedKonto1,
    selectedKonto2,
    selectedKonto3,
    selectedKonto4
  );
}

function ondatechangehere(eventArgs) {
  console.log('onDateChange');
  console.log(eventArgs);
  selectedDate.value = eventArgs;
}

function resetOveride() {
  amountOverride.value = 0;
}

function shiftDate(upDown) {
  let date = new Date(selectedDate.value);
  if (date instanceof Date) selectedDate.value = addDays(date, upDown);
}

function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function handleIncreaseDecrease(eventArgs) {
  if (eventArgs.increaseDecrease == 'increase') {
    amountOverride.value = amountOverride.value + eventArgs.amount;
  } else {
    amountOverride.value = amountOverride.value - eventArgs.amount;
  }
  // console.log(eventArgs);
}
</script>
